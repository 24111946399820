export const splitArrayToFit = (
    array: any[],
    width: number,
    itemWidth: number,
) => {
    const rows = Math.ceil((array.length * itemWidth) / width);
    return splitArray([...array], rows);
};

export const splitArray = (array: any[], n: number) => {
    /**
     * result chunks
     */
    const result = [];
    const itemsPerRow = Math.floor(array.length / n);
    // splice untill dead
    while (array.length) {
        result.push(array.splice(0, itemsPerRow));
    }
    /**
     * return result
     */
    return result;
};
