import React, { FC, useMemo } from 'react';
import { splitArrayToFit } from '../../../functions/splitArray';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { HeaderButton } from './HeaderButton';

const buttons = [
    'github',
    'stackoverflow',
    'twitter',
    'mail',
    'paypal',
    'tetris',
    'leetcode',
    'faceit',
    'steam',
    'twitch',
];

export const SubHeaderButtons: FC = () => {
    const windowSize = useWindowSize();
    const contentRows = useMemo(() => {
        if (!windowSize.width) {
            return [buttons];
        }
        return splitArrayToFit(buttons, windowSize.width, 60);
    }, [windowSize.width]);
    return (
        <div className="sub-header">
            {contentRows.map((cr, i) => (
                <div key={i} className="sub-header-row">
                    {cr.map((t) => (
                        <HeaderButton key={t} type={t} />
                    ))}
                </div>
            ))}
        </div>
    );
};
