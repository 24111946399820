import React, { FC } from 'react';

export const HexagonImage: FC<{ src: any; size: number; id: string }> = ({
    id,
    src,
    size,
}) => {
    return (
        <div style={{ width: size, height: size }}>
            <svg
                viewBox="0 0 100 100"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <pattern
                        id={id}
                        patternUnits="userSpaceOnUse"
                        width={`100`}
                        height={`100`}
                    >
                        <image
                            xlinkHref={src}
                            x="-25"
                            width="150"
                            height="100"
                        />
                    </pattern>
                </defs>
                <polygon
                    points="50 1 95 25 95 75 50 99 5 75 5 25"
                    fill={`url(#${id})`}
                />
            </svg>
        </div>
    );
};
