import React, { FC } from 'react';
import {
    dataBases,
    devops,
    engines,
    frameworks,
    languages,
    technologies,
} from '../../constants/experience';

import { ExperienceSection, ProHistory } from './components/';
import './home.css';

export const Home: FC = () => {
    return (
        <div className="home-container">
            <div className="paragraph bold">
                Tjelvar &quot;Nestoro&quot; Olsen,
            </div>
            <div className="paragraph">
                I am a Software Developer since 2017. My work experience mostly
                revolves around webapp development, including the respective
                backends and native mobile applications (basically the same
                thing).
            </div>
            <div className="paragraph">
                Whilst I would love to set foot in the video game industry, I am
                unhappy with the direction most larger companies are going! As a
                developer I would want major improvements in those before I
                could imagine working with or for them. This hopefully drives me
                into launching a project by myself if I find enough time and
                support.
            </div>
            <div className="section-headline">Experience</div>
            {/* <ExperienceSection headline="Languages" content={languages} />
            <ExperienceSection headline="Technologies" content={technologies} />
            <ExperienceSection headline="Frameworks" content={frameworks} />
            <ExperienceSection headline="Data Bases" content={dataBases} />
            <ExperienceSection headline="Dev-Ops" content={devops} />
            <ExperienceSection headline="3D - Engines" content={engines} /> */}
            <div className="paragraph">
                A comprehensive summary of what I have work experience with.
            </div>
            <ExperienceSection
                content={[
                    ...languages,
                    ...technologies,
                    ...frameworks,
                    ...dataBases,
                    ...devops,
                    ...engines,
                ]}
            />
            <div className="paragraph">
                * The list is most definetly incomplete and quality of exposure
                may vary
            </div>
            <div className="section-headline">Professional History</div>
            <ProHistory />
        </div>
    );
};
