import { useEffect, useState, useCallback, useMemo } from 'react';
import {
    hideHtmlElement,
    DIR,
    showHtmlElement,
    Piece,
    randomPiece,
    KEY,
    speed,
    forEachBlockOfPiece,
    courtHeight,
    courtWidth,
} from './helpers';
/**
 * tetris game state hook
 * @returns
 */
export const useTetrisState = () => {
    const [score, setScore] = useState(0);
    const [rows, setRowCount] = useState(0);
    const [current, setCurrent] = useState<{
        type: Piece;
        dir: number;
        x: number;
        y: number;
    } | null>(null);
    const [next, setNext] = useState<{
        type: Piece;
        dir: number;
        x: number;
        y: number;
    } | null>(null);
    const [blocks, setBlocks] = useState<(Piece | null)[][]>([]);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [tick, setTick] = useState(0);
    const playing = useMemo(
        () => startTime > 0 && !endTime,
        [startTime, endTime],
    );
    /**
     * get current tickrate for autofalling
     */
    const getStep = useCallback(() => {
        if (!startTime || !rows) {
            return speed.start;
        }
        return Math.max(speed.min, speed.start - speed.decrement * rows);
    }, [startTime, rows]);
    /**
     * set block at x, y
     */
    const setBlock = useCallback((x: number, y: number, type: Piece | null) => {
        setBlocks((prev) => {
            const newBlocks = [...prev];
            newBlocks[y] = newBlocks[y] || [];
            newBlocks[y][x] = type;
            return newBlocks;
        });
    }, []);
    /**
     * get block at x, y
     * callback updates when blocks change
     */
    const getBlock = useCallback(
        (x: number, y: number) => (blocks[y] ? blocks[y][x] : null),
        [blocks],
    );
    /**
     * callback to remove lines
     */
    const removeLines = useCallback((lines: number[]) => {
        setBlocks((blocks) =>
            [...Array(lines.length).fill(null), ...blocks].filter(
                (_, i) => !lines.includes(i - lines.length),
            ),
        );
    }, []);
    /**
     * check if block is occupied
     */
    const occupied = useCallback(
        (type: Piece, x: number, y: number, dir: number) => {
            let result = false;
            forEachBlockOfPiece(type, x, y, dir, (blockX, blockY) => {
                if (
                    blockX < 0 ||
                    blockX >= courtWidth ||
                    blockY < 0 ||
                    blockY >= courtHeight ||
                    getBlock(blockX, blockY)
                ) {
                    result = true;
                }
            });
            return result;
        },
        [getBlock],
    );
    /**
     * check if block is not occupied
     */
    const unoccupied = useCallback(
        (type: Piece, x: number, y: number, dir: number) =>
            !occupied(type, x, y, dir),
        [occupied],
    );
    /**
     * callback to reset game
     */
    const reset = useCallback(() => {
        setStartTime(0);
        setEndTime(0);
        setBlocks([]);
        setRowCount(0);
        setScore(0);
        setTick(0);
        setCurrent(randomPiece());
        setNext(randomPiece());
    }, []);
    /**
     * callback to start game
     */
    const play = useCallback(() => {
        hideHtmlElement('start');
        reset();
        setStartTime(Date.now());
    }, [reset]);
    /**
     * callback to lose game
     */
    const lose = useCallback(() => {
        showHtmlElement('start');
        setEndTime(Date.now());
    }, []);

    const move = useCallback(
        async (dir: number) =>
            new Promise((resolve) => {
                setCurrent((current) => {
                    if (!current) {
                        resolve(false);
                        return current;
                    }
                    let x = current.x;
                    let y = current.y;
                    switch (dir) {
                        case DIR.RIGHT:
                            x = x + 1;
                            break;
                        case DIR.LEFT:
                            x = x - 1;
                            break;
                        case DIR.DOWN:
                            y = y + 1;
                            break;
                    }
                    if (unoccupied(current.type, x, y, current.dir)) {
                        resolve(true);
                        return { ...current, x, y };
                    } else {
                        resolve(false);
                    }
                    return current;
                });
            }),
        [unoccupied],
    );

    const rotate = useCallback(() => {
        setCurrent((current) => {
            if (!current) return current;
            const newdir = current.dir === DIR.MAX ? DIR.MIN : current.dir + 1;
            if (unoccupied(current.type, current.x, current.y, newdir)) {
                return { ...current, dir: newdir };
            }
            return current;
        });
    }, [unoccupied]);

    const dropPiece = useCallback(() => {
        if (!current) return;
        forEachBlockOfPiece(
            current.type,
            current.x,
            current.y,
            current.dir,
            (x, y) => {
                setBlock(x, y, current.type);
            },
        );
    }, [current, setBlock]);

    const drop = useCallback(() => {
        if (!current) return;
        move(DIR.DOWN).then((result) => {
            if (!result) {
                setScore((score) => score + 10);
                dropPiece();
                setCurrent(next || randomPiece());
                setNext(randomPiece());
                if (occupied(current.type, current.x, current.y, current.dir)) {
                    lose();
                }
            }
        });
    }, [current, move, dropPiece, occupied, lose, next]);

    const handle = useCallback(
        (action: any) => {
            switch (action) {
                case DIR.LEFT:
                    move(DIR.LEFT);
                    break;
                case DIR.RIGHT:
                    move(DIR.RIGHT);
                    break;
                case DIR.UP:
                    rotate();
                    break;
                case DIR.DOWN:
                    drop();
                    break;
            }
        },
        [move, rotate, drop],
    );

    const update = useCallback(
        (action?: number) => {
            if (action) {
                handle(action);
            } else {
                drop();
            }
        },
        [handle, drop],
    );
    /**
     * handle keydown events
     */
    const keydown = useCallback(
        (ev: KeyboardEvent) => {
            let handled = false;
            if (playing) {
                switch (ev.keyCode) {
                    case KEY.LEFT:
                        handle(DIR.LEFT);
                        handled = true;
                        break;
                    case KEY.RIGHT:
                        handle(DIR.RIGHT);
                        handled = true;
                        break;
                    case KEY.UP:
                        handle(DIR.UP);
                        handled = true;
                        break;
                    case KEY.DOWN:
                        handle(DIR.DOWN);
                        handled = true;
                        break;
                    case KEY.ESC:
                        lose();
                        handled = true;
                        break;
                }
            } else if (ev.keyCode === KEY.SPACE) {
                play();
                handled = true;
            }
            if (handled) {
                ev.preventDefault(); // prevent arrow keys from scrolling the page (supported in IE9+ and all other browsers)
            }
        },
        [playing, handle, lose, play],
    );
    /**
     * effect to manage keydown event litener
     */
    useEffect(() => {
        document.addEventListener('keydown', keydown, false);

        return () => {
            document.removeEventListener('keydown', keydown);
        };
    }, [keydown]);
    /**
     * effect to check for completed lines
     */
    useEffect(() => {
        const completedLines: number[] = [];
        for (let y = courtHeight; y > 0; --y) {
            let complete = true;
            for (let x = 0; x < courtWidth; ++x) {
                if (!getBlock(x, y)) {
                    complete = false;
                }
            }
            if (complete) {
                completedLines.push(y);
            }
        }
        const n = completedLines.length;
        if (n > 0) {
            removeLines(completedLines);
            setRowCount((prev) => prev + n); // increment rowcount by n
            const nextScore = 100 * Math.pow(2, n - 1); // 1: 100, 2: 200, 3: 400, 4: 800
            setScore((score) => score + nextScore);
        }
    }, [getBlock, removeLines]);
    /**
     * consume tick if playing
     */
    useEffect(() => {
        if (playing && tick) {
            setTick(() => {
                update();
                return 0;
            });
        }
    }, [playing, tick, update]);
    /**
     * set tick if playing
     */
    useEffect(() => {
        if (playing && !tick) {
            const delta = getStep() * 1000;
            const t = setTimeout(() => setTick(delta), delta);
            return () => clearTimeout(t);
        }
    }, [playing, tick, getStep]);

    return {
        playing,
        score,
        rows,
        current,
        next,
        blocks,
        play,
        getBlock,
    };
};
