import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import logo from '../../../assets/tN-v5.3.svg';

import tetrisOutline from '../../../assets/icons/tetris.svg';
import tetrisFilled from '../../../assets/icons/tetris_filled.svg';
import github from '../../../assets/icons/github.svg';
import steam from '../../../assets/icons/steam.svg';
import stackoverflow from '../../../assets/icons/stackoverflow.svg';
import twitch from '../../../assets/icons/twitch.svg';
import twitter from '../../../assets/icons/twitter.svg';
import mail from '../../../assets/icons/mail.svg';
import leetcode from '../../../assets/icons/leetcode.svg';
import paypal from '../../../assets/icons/paypal.svg';
import faceit from '../../../assets/icons/faceit.svg';

export const HeaderButton: FC<{
    type:
        | 'tetris'
        | 'github'
        | 'steam'
        | 'stackoverflow'
        | 'twitch'
        | 'twitter'
        | 'mail'
        | 'paypal'
        | 'faceit'
        | 'leetcode';
}> = ({ type }) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const src = useMemo(() => {
        switch (type) {
            case 'tetris':
                return tetrisOutline;
            case 'github':
                return github;
            case 'steam':
                return steam;
            case 'stackoverflow':
                return stackoverflow;
            case 'twitch':
                return twitch;
            case 'twitter':
                return twitter;
            case 'mail':
                return mail;
            case 'faceit':
                return faceit;
            case 'leetcode':
                return leetcode;
            case 'paypal':
                return paypal;
            default:
                return logo;
        }
    }, [type]);
    const secondarySrc = useMemo(() => {
        switch (type) {
            case 'tetris':
                return tetrisFilled;
            default:
                return undefined;
        }
    }, [type]);
    /**
     * callback to handle button clicks
     */
    const onClick = useCallback(() => {
        switch (type) {
            case 'tetris':
                return navigate('/tetris');
            case 'github':
                return window.open('https://github.com/Nestoro');
            case 'steam':
                return window.open('https://steamcommunity.com/id/tenestoro/');
            case 'twitch':
                return window.open('https://www.twitch.tv/tenestoro');
            case 'twitter':
                return window.open('https://x.com/teNestoro');
            case 'stackoverflow':
                return window.open(
                    'https://stackoverflow.com/users/7941160/nestoro',
                );
            case 'mail':
                return window.open('mailto:me+you@nestoro.de');
            case 'paypal':
                return window.open('https://www.paypal.com/paypalme/abunesto');
            case 'leetcode':
                return window.open('https://leetcode.com/u/Nestoro/');
            case 'faceit':
                return window.open('https://www.faceit.com/en/players/Nestoro');
        }
    }, [type]);
    /**
     * render
     */
    return (
        <div className="header-button" onClick={onClick}>
            <img
                src={hover && secondarySrc ? secondarySrc : src}
                width={hover ? 36 : 32}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className="text-color-svg header-button-svg"
            />
        </div>
    );
};
