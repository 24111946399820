import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import { Home } from './components/Home';
import { Tetris } from './components/Tetris';
import { Header } from './components/Header';

export const App: FC = () => {
    return (
        <div className="App">
            <Header />
            <Routes>
                <Route path="/" Component={Home} />
                <Route path="/tetris" Component={Tetris} />
            </Routes>
        </div>
    );
};
