import React, { FC, useRef } from 'react';
import { useTetrisState } from './functions/useTetrisState';
import './tetris.css';
import { useDrawTetris } from './functions/useDrawTetris';

export const Tetris: FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const upcomingRef = useRef<HTMLCanvasElement>(null);
    const { current, next, score, rows, getBlock } = useTetrisState();
    useDrawTetris(canvasRef, upcomingRef, current, next, getBlock);

    return (
        <div className="content-box">
            <div id="menu" className="tetris-part">
                <p id="start">
                    <a>Press Space to Play.</a>
                </p>
                <p>
                    <canvas
                        ref={upcomingRef}
                        id="upcoming"
                        className="tetris-canvas"
                    ></canvas>
                </p>
                <p>
                    score{' '}
                    <span id="score">
                        {('00000' + Math.floor(score)).slice(-5)}
                    </span>
                </p>
                <p>
                    rows <span id="rows">{rows}</span>
                </p>
            </div>
            <div id="gameframe" className="tetris-part">
                <canvas ref={canvasRef} id="t-canvas" className="tetris-canvas">
                    your browser does not support the &lt;canvas&gt; element
                </canvas>
            </div>
            <div id="placeholder" className="tetris-part"></div>
        </div>
    );
};
