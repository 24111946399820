import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useWindowSize } from '../../hooks/useWindowSize';
import { HeaderButton, SubHeaderButtons } from './components/';

import logo from '../../assets/tN-v5.3.svg';
import './header.css';

export const Header: FC = () => {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const isWide = useMemo(() => windowSize.width > 900, [windowSize.width]);
    return (
        <>
            <div className="header">
                {isWide ? (
                    <div className="header-buttons">
                        <HeaderButton type="github" />
                        <HeaderButton type="stackoverflow" />
                        <HeaderButton type="twitter" />
                        <HeaderButton type="mail" />
                        <HeaderButton type="paypal" />
                    </div>
                ) : (
                    <div className="header-line" />
                )}
                <div
                    className="header-logo-container"
                    onClick={() => navigate('/')}
                >
                    <img
                        className="header-logo text-color-svg"
                        src={logo}
                        alt="logo"
                        width={120}
                    />
                </div>
                {isWide ? (
                    <div className="header-buttons">
                        <HeaderButton type="tetris" />
                        <HeaderButton type="leetcode" />
                        <HeaderButton type="faceit" />
                        <HeaderButton type="steam" />
                        <HeaderButton type="twitch" />
                    </div>
                ) : (
                    <div className="header-line" />
                )}
            </div>
            {!isWide && <SubHeaderButtons />}
        </>
    );
};
