import React, { FC } from 'react';
import kone from '../../../../assets/images/kone.png';
import luh from '../../../../assets/images/luh.png';
import mango from '../../../../assets/images/mango.png';
import mundoIt from '../../../../assets/images/mundoit.png';
import novanation from '../../../../assets/images/novanation.jpg';
import ptc from '../../../../assets/images/ptc.png';
import qwento from '../../../../assets/images/qwento.jpg';
import racecoin from '../../../../assets/images/racecoin.png';
import thesis from '../../../../assets/images/thesis.svg';
import timeployees from '../../../../assets/images/timeployees.png';
import vw from '../../../../assets/images/vw.jpg';
import { HexagonImage } from '../../../elements/HexagonImage';

import './history.css';

export const ProHistory: FC = () => {
    return (
        <div className="history-container">
            <div
                className="history-row"
                onClick={() => window.open('https://novanation.de')}
            >
                <HexagonImage size={64} src={novanation} id={'novanation'} />
                <div>
                    <div className="history-title">NovaNation</div>
                    <div className="history-sub-title">Jul. 2022 - Present</div>
                </div>
            </div>
            <div className="history-content">
                <div className="history-paragraph">
                    I work as lead full stack developer on several projects for
                    multiple fields of interest of the company. During my work
                    here i created several applications mostly utilizing react,
                    react-native, python and firebase.
                </div>
                <div
                    className="history-point-of-interest"
                    onClick={() => window.open('https://timeployees.de/')}
                >
                    <div>
                        <HexagonImage
                            size={48}
                            src={timeployees}
                            id={'timeployees'}
                        />
                    </div>
                    <div className="history-point-of-interest-text">
                        We have developed a platform for employment leasing
                        companies. To do so we made mostly use of firebase and
                        react-native-web.
                    </div>
                </div>
                {/* <div
                    className="history-point-of-interest"
                    onClick={() => window.open('https://situsdocs.de/')}
                >
                    <div>
                        <HexagonImage size={48} src={situs} id={'situs'} />
                    </div>
                    <div className="history-point-of-interest-text">
                        I developed tools to automate routine tasks required for
                        employment leasing.
                    </div>
                </div> */}
            </div>
            <div
                className="history-row"
                onClick={() => window.open('https://uni-hannover.de/de/')}
            >
                <HexagonImage size={64} src={luh} id={'luh'} />
                <div>
                    <div className="history-title">
                        Leibniz University Hannover
                    </div>
                    <div className="history-sub-title">
                        Jul. 2022: Bachelor of Science (B.Sc.)
                    </div>
                </div>
            </div>
            <div className="history-content">
                <div className="history-paragraph">
                    I finished my bachelor&apos;s degree in Computer Science
                    from Leibniz University Hannover, where I studied for
                    several years.
                </div>
                <div
                    className="history-point-of-interest"
                    onClick={() =>
                        window.open(
                            'https://www.pi.uni-hannover.de/fileadmin/pi/se/Stud-Arbeiten/2022/BA-Olsen-2022.pdf',
                        )
                    }
                >
                    <div>
                        <HexagonImage size={48} src={thesis} id={'luhthesis'} />
                    </div>
                    <div className="history-point-of-interest-text">
                        For my Bachelor thesis, I developed a graphical user
                        interface for the analysis of sentiments in development
                        teams. Sadly, the application was not published, but the
                        thesis is available.
                    </div>
                </div>
            </div>
            <div
                className="history-row"
                onClick={() => window.open('https://mundo-it.de/')}
            >
                <HexagonImage size={64} src={mundoIt} id={'mundoit'} />
                <div>
                    <div className="history-title">MundoIT</div>
                    <div className="history-sub-title">
                        Nov. 2017 - Jul. 2022
                    </div>
                </div>
            </div>
            <div className="history-content">
                MundoIT was a contract developer where is started out as a
                working student full stack developer. During my time there we
                applied multiple different technologies to realize frontend.
                Those included ionic, angular, react-native and swift. For our
                backend we mostly made use of nodeJS, graphql, mongodb and
                mysql. Additionally to our contracts we developed software to
                solve in house problems.
                <div
                    className="history-point-of-interest"
                    onClick={() => window.open('https://race-coin.io/')}
                >
                    <div>
                        <HexagonImage size={48} src={mango} id={'mango'} />
                    </div>
                    <div className="history-point-of-interest-text">
                        We developed a whitelable product based on one of our
                        contracts. But this secret project never saw the light
                        of day.
                    </div>
                </div>
                <div
                    className="history-point-of-interest"
                    onClick={() => window.open('https://www.puratec.de/')}
                >
                    <div>
                        <HexagonImage size={48} src={ptc} id={'ptc'} />
                    </div>
                    <div className="history-point-of-interest-text">
                        For the tank cleaning company puratec we have developed
                        a performance capture software.
                    </div>
                </div>
                <div
                    className="history-point-of-interest"
                    onClick={() => window.open('https://race-coin.io/')}
                >
                    <div>
                        <HexagonImage
                            size={48}
                            src={racecoin}
                            id={'racecoin'}
                        />
                    </div>
                    <div className="history-point-of-interest-text">
                        RaceCoin was a phantom racing and betting platform. We
                        employed machine learning to develop a algorithm which
                        would factor out car, track, weather and other
                        influences to rate the drive directly. This enabled a
                        new form of competition.
                    </div>
                </div>
                <div
                    className="history-point-of-interest"
                    onClick={() => window.open('https://qwento.de/')}
                >
                    <div>
                        <HexagonImage size={48} src={qwento} id={'qwento'} />
                    </div>
                    <div className="history-point-of-interest-text">
                        QWENTO is a iPad cashier system for gastronomy which we
                        developed primarily for our in house partner gastronomy.
                    </div>
                </div>
                <div
                    className="history-point-of-interest"
                    onClick={() => window.open('https://www.kone.de/')}
                >
                    <div>
                        <HexagonImage size={48} src={kone} id={'kone'} />
                    </div>
                    <div className="history-point-of-interest-text">
                        For the local elevator company KONE We developed a
                        magazine app for internal communication.
                    </div>
                </div>
                <div
                    className="history-point-of-interest"
                    onClick={() =>
                        window.open('https://www.der-goldene-bulli.de/')
                    }
                >
                    <div>
                        <HexagonImage size={48} src={vw} id={'vw'} />
                    </div>
                    <div className="history-point-of-interest-text">
                        For the events &quot;Der goldene Bulli 2016&quot; and
                        &quot;Der goldene Bulli 2017&quot; we developed the
                        Website
                    </div>
                </div>
            </div>
        </div>
    );
};
