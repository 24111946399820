import { useCallback, useEffect, useState } from 'react';
import {
    Piece,
    forEachBlockOfPiece,
    courtHeight,
    courtWidth,
    nu,
    setHtml,
    frameDuration,
} from './helpers';
/**
 * technically this hook is a bit of a mess and the rendering engine behind the tetris game
 * @param canvasRef
 * @param upcomingRef
 * @param current
 * @param next
 * @param rows
 * @param getBlock
 */
export const useDrawTetris = (
    canvasRef: React.RefObject<HTMLCanvasElement>,
    upcomingRef: React.RefObject<HTMLCanvasElement>,
    current: { type: Piece; dir: number; x: number; y: number } | null,
    next: { type: Piece; dir: number; x: number; y: number } | null,
    getBlock: (x: number, y: number) => Piece | null,
) => {
    const [dx, setDx] = useState<number>();
    const [dy, setDy] = useState<number>();
    const [vscore, setVscore] = useState(0);
    const drawBlock = useCallback(
        (
            ctx: CanvasRenderingContext2D,
            x: number,
            y: number,
            color: string,
        ) => {
            if (dx === undefined || dy === undefined) return;
            ctx.fillStyle = color;
            ctx.fillRect(x * dx, y * dy, dx, dy);
            ctx.strokeRect(x * dx, y * dy, dx, dy);
        },
        [dx, dy],
    );

    const drawPiece = useCallback(
        (
            ctx: CanvasRenderingContext2D,
            type: Piece,
            x: number,
            y: number,
            dir: number,
        ) => {
            forEachBlockOfPiece(type, x, y, dir, (nx, ny) => {
                drawBlock(ctx, nx, ny, type.color);
            });
        },
        [drawBlock],
    );

    const drawCourt = useCallback(
        (ctx: CanvasRenderingContext2D) => {
            if (dx === undefined || dy === undefined) return;
            if (!canvasRef.current) return;

            ctx.clearRect(
                0,
                0,
                canvasRef.current.width,
                canvasRef.current.height,
            );
            if (current) {
                drawPiece(ctx, current.type, current.x, current.y, current.dir);
            }
            for (let y = 0; y < courtHeight; y++) {
                for (let x = 0; x < courtWidth; x++) {
                    const block = getBlock(x, y);
                    if (block) {
                        drawBlock(ctx, x, y, block.color);
                    }
                }
            }
            ctx.strokeRect(0, 0, courtWidth * dx - 1, courtHeight * dy - 1); // court boundary
        },
        [dx, dy, canvasRef, drawPiece, current, getBlock, drawBlock],
    );

    const drawNext = useCallback(
        (uctx: CanvasRenderingContext2D) => {
            if (dx === undefined || dy === undefined) return;
            if (!next) return;
            const padding = (nu - next.type.size) / 2; // half-arsed attempt at centering next piece display
            uctx.save();
            uctx.translate(0.5, 0.5);
            uctx.clearRect(0, 0, nu * dx, nu * dy);
            drawPiece(uctx, next.type, padding, padding, next.dir);
            uctx.strokeStyle = 'black';
            uctx.strokeRect(0, 0, nu * dx - 1, nu * dy - 1);
            uctx.restore();
        },
        [dx, dy, next, drawPiece],
    );

    const draw = useCallback(() => {
        const canvas = canvasRef.current;
        const ucanvas = upcomingRef.current;
        if (!canvas || !ucanvas) return;

        const ctx = canvas.getContext('2d');
        const uctx = ucanvas.getContext('2d');
        if (!ctx || !uctx) return;

        ctx.save();
        ctx.lineWidth = 1;
        ctx.translate(0.5, 0.5); // for crisp 1px black lines
        drawCourt(ctx);
        drawNext(uctx);
        ctx.restore();
    }, [canvasRef, upcomingRef, drawCourt, drawNext]);

    const resize = useCallback(() => {
        const canvas = canvasRef.current;
        const ucanvas = upcomingRef.current;
        if (!canvas || !ucanvas) return;

        canvas.width = canvas.clientWidth; // set canvas logical size equal to its physical size
        canvas.height = canvas.clientHeight; // (ditto)
        ucanvas.width = ucanvas.clientWidth;
        ucanvas.height = ucanvas.clientHeight;
        setDx(canvas.width / courtWidth); // pixel size of a single tetris block
        setDy(canvas.height / courtHeight); // (ditto)
    }, [canvasRef, upcomingRef]);
    /**
     * effect to manage resize events
     */
    useEffect(() => {
        window.addEventListener('resize', resize, false);
        resize(); // setup all our sizing information
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [resize]);

    useEffect(() => {
        let requestAnimationFrameRef = 0;
        let now = Date.now();
        let last = now;
        let delta = 0;
        const frame = () => {
            now = Date.now();
            delta += now - last;
            last = now;
            if (delta >= frameDuration) {
                draw();
                delta = delta % frameDuration;
            }
            requestAnimationFrameRef = window.requestAnimationFrame(frame);
        };
        frame(); // start the first frame

        return () => {
            if (requestAnimationFrameRef) {
                window.cancelAnimationFrame(requestAnimationFrameRef);
            }
        };
    }, [draw]);
};
