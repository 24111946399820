import React, { FC, useMemo } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { splitArrayToFit } from '../../../functions/splitArray';

export const ExperienceSection: FC<{
    headline?: string;
    content: string[][];
}> = ({ headline, content }) => {
    const windowSize = useWindowSize();
    const contentRows = useMemo(() => {
        if (!windowSize.width) {
            return [content];
        }
        return splitArrayToFit(
            content,
            windowSize.width < 1080 ? windowSize.width : 1080,
            150,
        );
    }, [windowSize.width, content]);

    return (
        <div>
            {!!headline && (
                <div className="subsection-headline">{headline}</div>
            )}
            {contentRows.map((cr, i) => (
                <div key={i} className="experience-container">
                    {cr.map((experience) => (
                        <div
                            key={experience[0]}
                            className="experience-button"
                            onClick={() => window.open(experience[1])}
                        >
                            <img width={32} src={experience[2]} />
                            <a>{experience[0].toUpperCase()}</a>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
