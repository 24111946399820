import angular from '../assets/icons/experience/angular.svg';
import c from '../assets/icons/experience/c.svg';
import cpp from '../assets/icons/experience/cpp.svg';
import csharp from '../assets/icons/experience/csharp.svg';
import docker from '../assets/icons/experience/docker.svg';
import electron from '../assets/icons/experience/electron.svg';
import firebase from '../assets/icons/experience/firebase.svg';
import graphql from '../assets/icons/experience/graphql.svg';
import java from '../assets/icons/experience/java.svg';
import js from '../assets/icons/experience/js.svg';
import mongo from '../assets/icons/experience/mongo.svg';
import mysql from '../assets/icons/experience/mysql.svg';
import nodejs from '../assets/icons/experience/nodejs.svg';
import php from '../assets/icons/experience/php.svg';
import python from '../assets/icons/experience/python.svg';
import react from '../assets/icons/experience/react.svg';
import swift from '../assets/icons/experience/swift.svg';
import ts from '../assets/icons/experience/ts.svg';
import ue from '../assets/icons/experience/ue.svg';
import unity from '../assets/icons/experience/unity.svg';
import github from '../assets/icons/experience/github.svg';
import gitlab from '../assets/icons/experience/gitlab.svg';
import git from '../assets/icons/experience/git.svg';
import socketio from '../assets/icons/experience/socketio.svg';

export const languages = [
    ['c', 'https://devdocs.io/c/', c],
    ['c++', 'https://www.cplusplus.com/', cpp],
    ['c#', 'https://docs.microsoft.com/en-us/dotnet/csharp/', csharp],
    ['swift', 'https://developer.apple.com/swift/', swift],
    ['python', 'https://www.python.org/', python],
    ['javascript', 'https://www.javascript.com/', js],
    ['typescript', 'https://www.typescriptlang.org/', ts],
    ['php', 'https://www.php.net/', php],
    ['java', 'https://www.java.com/en/', java],
];

export const technologies = [
    ['graphql', 'https://graphql.org/', graphql],
    ['socket.io', 'https://socket.io/', socketio],
];

export const frameworks = [
    ['react-native', 'https://reactnative.dev/', react],
    ['angular', 'https://angular.io/', angular],
    ['electron', 'https://www.electronjs.org/', electron],
    ['node', 'https://nodejs.org/en/', nodejs],
];

export const engines = [
    ['unreal', 'https://www.unrealengine.com/en-US/', ue],
    ['unity', 'https://unity.com/', unity],
];

export const devops = [
    ['docker', 'https://www.docker.com/', docker],
    ['git', 'https://git-scm.com/', git],
    ['github', 'https://www.github.com/', github],
    ['gitlab', 'https://about.gitlab.com/', gitlab],
];

export const dataBases = [
    ['mongoDB', 'https://www.mongodb.com/', mongo],
    ['mySQL', 'https://www.mysql.com/', mysql],
    ['firebase', 'https://firebase.google.com/', firebase],
];
