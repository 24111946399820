export type Piece = {
    size: number;
    blocks: number[];
    color: string;
};

export const DIR = { UP: 0, RIGHT: 1, DOWN: 2, LEFT: 3, MIN: 0, MAX: 3 };
export const KEY = {
    ESC: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
};
export const speed = { start: 0.6, decrement: 0.005, min: 0.1 }; // how long before piece drops by 1 row (seconds)
export const courtWidth = 10; // width of tetris court (in blocks)
export const courtHeight = 20; // height of tetris court (in blocks)
export const nu = 5; // width/height of upcoming preview (in blocks)
export const fps = 60;
export const frameDuration = 1000 / fps;

const i: Piece = {
    size: 4,
    blocks: [0x0f00, 0x2222, 0x00f0, 0x4444],
    color: 'cyan',
};
const j: Piece = {
    size: 3,
    blocks: [0x44c0, 0x8e00, 0x6440, 0x0e20],
    color: 'blue',
};
const l: Piece = {
    size: 3,
    blocks: [0x4460, 0x0e80, 0xc440, 0x2e00],
    color: 'orange',
};
const o: Piece = {
    size: 2,
    blocks: [0xcc00, 0xcc00, 0xcc00, 0xcc00],
    color: 'yellow',
};
const s: Piece = {
    size: 3,
    blocks: [0x06c0, 0x8c40, 0x6c00, 0x4620],
    color: 'green',
};
const t: Piece = {
    size: 3,
    blocks: [0x0e40, 0x4c40, 0x4e00, 0x4640],
    color: 'purple',
};
const z: Piece = {
    size: 3,
    blocks: [0x0c60, 0x4c80, 0xc600, 0x2640],
    color: 'red',
};
const pieces = [
    i,
    i,
    i,
    i,
    j,
    j,
    j,
    j,
    l,
    l,
    l,
    l,
    o,
    o,
    o,
    o,
    s,
    s,
    s,
    s,
    t,
    t,
    t,
    t,
    z,
    z,
    z,
    z,
];

export const hideHtmlElement = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;
    element.style.visibility = 'hidden';
};
export const showHtmlElement = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;
    // @ts-ignore
    element.style.visibility = null;
};
export const setHtml = (id: string, html: string) => {
    const element = document.getElementById(id);
    if (!element) return;
    element.innerHTML = html;
};
export const random = (min: number, max: number) =>
    min + Math.random() * (max - min);

export const randomPiece = () => {
    const type = [...pieces].splice(random(0, pieces.length - 1), 1)[0];
    return {
        type: type,
        dir: DIR.UP,
        x: Math.round(random(0, courtWidth - type.size)),
        y: 0,
    };
};

export const forEachBlockOfPiece = (
    type: Piece,
    x: number,
    y: number,
    dir: number,
    fn: (nx: number, ny: number) => void,
) => {
    let row = 0,
        col = 0;
    const blocks = type.blocks[dir];
    for (let bit = 0x8000; bit > 0; bit = bit >> 1) {
        if (blocks & bit) {
            fn(x + col, y + row);
        }
        if (++col === 4) {
            col = 0;
            ++row;
        }
    }
};

export const getBlock = (blocks: (Piece | null)[][], x: number, y: number) =>
    blocks[x] ? blocks[x][y] : null;
